<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
//import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "Remboursements",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],
      messageSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      ordersData: [
        {
          username: "Maturin NAGO",
          tel: "61859678",
          vehicule: "Lexus RX 450 - BH 5645",
          dateIns: "16 Nov - 18 Nov",
          montant: "36 000 FCFA",
        },
        {
          username: "Aimé ",
          tel: "63569874",
          vehicule: "Camry XLE - AL 2206",
          dateIns: "16 Nov - 22 Nov",
          montant: "38 000 FCFA",
        },
        {
          username: "loca Motors",
          tel: "61859678",
          vehicule: "Mercedes 350 - BU 2596",
          dateIns: "15 Nov - 30 Nov",
          montant: "41 000 FCFA",
        },
        {
          username: "KOSSOUGBETO Landry",
          tel: "63569874",
          vehicule: "Mazda - BY 6396",
          dateIns: "14 Nov - 15 Nov",
          montant: "16 000 FCFA",
        },
      ],
      fields: [
        { key: "username", sortable: true, label: "Nom & prénoms locataire" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "vehicule", sortable: true, label: "Véhicule loué" },
        { key: "dateIns", sortable: true, label: "Période réservation" },
        { key: "montant", label: "Montant" },
        { key: "action", label: "Actions" },
      ],
      historyData: [
        {
          username: "KOSSOU Ange",
          montant: "16 000 FCFA",
          date: "12 Nov",
          admin: "Raynald",
        },
        {
          username: "BOUBAKAR Idriss",
          montant: "26 000 FCFA",
          date: "02 Fev",
          admin: "Nobel",
        },
        {
          username: "ABOU Mohamed",
          montant: "16 000 FCFA",
          date: "12 Avril",
          admin: "Raynis",
        },
        {
          username: "KOSSOUGBETO Landry",
          montant: "16 000 FCFA",
          date: "12 Nov",
          admin: "Raynis",
        },
      ],
      histoFields: [
        { key: "username", sortable: true, label: "Nom & prénoms locataire" },
        { key: "montant", label: "Montant" },
        { key: "date", label: "Date remboursement"},
        { key: "admin", label: "Admin"},
        //{ key: "action", label: "Actions" },
      ],
      showDisable: false,
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      userToUse: 0,
      infoUser:{
        firstname:"",
        lastname:"",
        boitepostale:"",
        profession:"",
        birthday:"",
        adresse:"",
        ville:"",
        pays:"",
        tel:"",
        email:"", 
      },
      showMessage: false,
      editor: ClassicEditor,
      editorData: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
    const data = await apiRequest("GET", "admin/users", undefined, false);
    if (data && data.data) {
      //console.log("data.data:", data.data);
      const formattedTable = data.data.map((user) => {
        return {
           id: user.id,
          username: user.lastname[0]==undefined ? "Nom Indéfini" : user.lastname[0] + ". " + user.firstname==undefined ? "Prénom Indéfini" : user.firstname,
          firstname:user.firstname,
          lastname:user.lastname,
          pays:user.pays,
          tel: user.tel,

          trajet: user.nombreTrajet,
          dateIns: format(
            new Date(user.dateCreation),
            "dd-MM-yyyy hh:mm:ss"
          ),
          badge: user.userValidated,
          statut: user.active ? "Actif" : "Suspendu",
        };
      });
      this.dataUser = formattedTable;
      console.log("data",data.data)
    }

    const dataD = await apiRequest("GET", "admin/users-inactif", undefined, false);
    if (dataD && dataD.data) {
      /*const formattedTableD = dataD.data.map((user) => {
          return {
           id: user.id,
          username: user.lastname[0]==undefined ? "Nom Indéfini" : user.lastname[0] + ". " + user.firstname==undefined ? "Prénom Indéfini" : user.firstname,
          firstname:user.firstname,
          lastname:user.lastname,
          tel: user.tel,
          trajet: user.nombreTrajet,
          dateIns: format(
            new Date(user.dateCreation),
            "dd-MM-yyyy hh:mm:ss"
          ),
          badge: user.userValidated,
          statut: user.active ? "Actif" : "Suspendu",
        };
      });*/

      const dataD = await apiRequest(
        "GET",
        "admin/users-inactif",
        undefined,
        false
      );
      if (dataD && dataD.data) {
        const formattedTableD = dataD.data.map((user) => {
          return {
            id: user.id,
            username:
              user.lastname[0] == undefined
                ? "Nom Indéfini"
                : user.lastname[0] + ". " + user.firstname == undefined
                ? "Prénom Indéfini"
                : user.firstname,
            tel: user.tel,
            trajet: user.nombreTrajet,
            dateIns: format(new Date(user.dateCreation), "dd-MM-yyyy hh:mm:ss"),
            badge: user.userValidated,
            statut: user.active ? "Actif" : "Suspendu",
          };
        });

        this.dataUserD = formattedTableD;
      }
      // Set the initial number of items
      this.totalRows = this.dataUser.length;
      this.nbreUser = this.dataUser.length;
    }},
    async addMessage() {
      this.showMessage = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
        console.log(data);
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
    },
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    editClick(row){
      this.showEdit = true;
    console.log("LAST NAME: ",row)
     this.infoUser.lastname=row.lastname;
     this.infoUser.firstname=row.firstname
     console.log("LAST NAME: ",row.firstname)
    },
    desactiverUser(row){
      this.userToUse = row.id
      //console.log('userToDel:', this.userToUse)
      this.showDisable = true;
    },
    async desactiveUser() {
      this.showDisable = false;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    messageShow(row) {
      this.showMessage = true;
      this.messageContent.userId = row.id;
      console.log(row.id);
    },
    async activeUser(row) {
      this.userToUse = row.id;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    deleteUser(row) {
      this.userToUse = row.id;
      //console.log('userToDel:', this.userToUse)
      this.showDelete = true;
    },
    async deletUser() {
      this.showDelete = false;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    /* goToDetail(row) {
      console.log("row:", row);
      router.push(`/user/user_info/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    }, */
    hideRow(row){
      if(row.clicked == true){
        console.log("test")
      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <h4 class="card-title mt-4">
              
            </h4>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Réservations annulées</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor:pointer;"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="hideRow(row)"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="text-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Valider"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a> -->
                      <a
                        href="javascript:void(0);"
                        class="btn btn-warning"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Rembourser"
                      >
                        <i class="font-size-2">Remboursement</i>
                      </a>
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="messageShow(row.item)"
                        v-b-tooltip.hover
                        title="Message"
                      >
                        <i class="fas fa-envelope"></i>
                      </a> -->
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Historique des remboursements</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor:pointer;"
                    class="table-centered"
                    :items="historyData"
                    :fields="histoFields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="hideRow(row)"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="text-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Valider"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Refuser"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="messageShow(row.item)"
                        v-b-tooltip.hover
                        title="Message"
                      >
                        <i class="fas fa-envelope"></i>
                      </a> -->
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDisable"
      title="Voulez-vous vraiment éffectuer le virement ?"
      title-class="font-18"
      hide-footer
    >
    <div class="row">
      <div class="col-md-6">
        <h5>Nom & Prénoms clients</h5>
        <span class="badge badge-success font-size-20">Raynis</span>
      </div>
      <div class="col-md-6">
        <h5>Numéro de téléphone</h5>
        <span class="badge badge-info font-size-20">61759048</span>
      </div>
    </div>

    <hr class="divider">
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletUser"
          >Oui</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Message envoyé avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >

    <form class="form-horizontal" role="form" method="post" @submit.prevent="EditSubmit">
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="lastname">Nom</label>
          <input type="text" class="form-control py-4" id="lastname" placeholder="" v-model="infoUser.lastname" >
        </div>
        <div class="col-md-4 mb-3">
          <label for="firstname">Prénom</label>
          <input type="text" class="form-control py-4" id="firstname" placeholder="" v-model="infoUser.firstname" > 
        </div>
        <div class="col-md-4 mb-3">
          <label for="firstname">Boîte postale</label>
          <input type="text" class="form-control py-4" id="boitepostale" placeholder=""   v-model="infoUser.boitepostale">
        </div>
        <div class="col-md-4 mb-3">
          <label for="firstname">Profession</label>
          <input type="text" class="form-control py-4" id="profession" placeholder="" v-model="infoUser.profession"> 
        </div>
        <div class="col-md-4 mb-3">
          <div class="mb-3">
            <label for="birthday">Date de naissance</label>
              <date-picker id="birthday" type="date" ></date-picker>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="lastname">Adresse</label>
          <input type="text" class="form-control py-4" placeholder=""  >
        </div>
        <div class="col-md-4 mb-3">
          <label for="firstname">Ville</label>
          <input type="text" class="form-control py-4" id="firstname3" placeholder="" > 
        </div>
        <div class="col-md-4 mb-3">
          <label for="firstname">Pays</label>
          <input type="text" class="form-control py-4" id="firstname4" placeholder="" > 
        </div>
        <div class="col-md-4 mb-3">
          <label for="lastname">Téléphone</label>
          <input type="text" class="form-control py-4" id="lastname5" placeholder=""  >
        </div>
        <div class="col-md-4 mb-3">
          <label for="firstname">Email</label>
          <input type="text" class="form-control py-4" id="firstname7" placeholder="" > 
        </div>
        <div class="col-md-4 mb-3">
          <label for="firstname">Mot de passe</label>
          <input type="text" class="form-control py-4" id="firstname6" placeholder="" > 
        </div>
      </div>
      <button class="btn btn-success float-right" type="submit">Modifier</button>
    </form>
    </b-modal>

    <!-- ENVOYER LE MESSAGE-->
    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="name"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>
